<template>
  <div>
    <b-card>
      <b-row class="justify-content-center">
        <b-col cols="6">
          <b-form-group label="Account" label-for="account-filter" class="">
            <v-select id="account-filter" v-model="selectedAccount" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title" :options="accounts" accountSelected="" @input="showAccApps" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col cols="6" offset="6" class="mb-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0 d-flex align-items-center">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <validation-observer ref="addCredentials" #default="{ invalid }">
          <b-modal id="modal-default" ok-variant="outline-success" ok-title="Save" scrollable size="lg"
            modal-class="modal-default" centered title="Save Credentials" @ok="saveCredentials" :ok-disabled="invalid">
            <!-- ADD -->
            <b-form v-if="addOrEdit == 'add'" @submit.prevent="saveCredentials">
              <b-container>
                <b-row class="justify-content-md-center">
                  <b-col md="6" cols="12">
                    <b-form-group label="App ID *" label-for="app-id">
                      <validation-provider #default="{ errors }" name="App ID" rules="required">
                        <b-form-input id="app-id" v-model="appId" placeholder="App ID"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="justify-content-md-center">
                  <b-col md="6" cols="12">
                    <b-form-group label="Api Key *" label-for="app-id">
                      <validation-provider #default="{ errors }" name="Api Key" rules="required">
                        <b-form-input id="api-key" v-model="api_key" placeholder="Api key"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
            <!-- EDIT -->
            <b-form v-if="addOrEdit == 'edit'" @submit.prevent="saveCredentials">
              <b-container>
                <b-row class="justify-content-md-center">
                  <b-col md="6" cols="12">
                    <b-form-group label="App ID *" label-for="app-id">
                      <validation-provider #default="{ errors }" name="App ID" rules="required">
                        <b-form-input id="app-id" v-model="appId" placeholder="App ID"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="justify-content-md-center">
                  <b-col md="6" cols="12">
                    <b-form-group label="Api Key *" label-for="app-id">
                      <validation-provider #default="{ errors }" name="Api Key" rules="required">
                        <b-form-input id="api-key" v-model="api_key" placeholder="Api key"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
            <!-- </b-card> -->
          </b-modal>
        </validation-observer>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(credentials)="data">
              <b-button-group>
                <b-button v-if="!data.item.credentials_id" v-ripple.400="'rgba(56, 144, 226, 0.15)'"
                  variant="flat-primary" class="btn-icon rounded-circle" v-b-tooltip.hover title="Add Credentials"
                  @click="setAppToAddCredentials(data.item)">
                  <feather-icon icon="PlusCircleIcon" size="20" />
                </b-button>
                <b-button v-if="data.item.credentials_id" v-ripple.400="'rgba(56, 144, 226, 0.15)'" variant="flat-primary"
                  class="btn-icon rounded-circle" v-b-tooltip.hover title="Edit Credentials"
                  @click="setAppToEditCredentials(data.item)">
                  <feather-icon icon="EditIcon" size="20" />
                </b-button>
              </b-button-group>
            </template>
            <template #cell(rate)="data"> {{ data.value }} / 5 </template>
          </b-table>
        </b-col>

        <b-col cols="6">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { required, regex, url } from "@validations";
import {
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import store from "@/store";
import router from "@/router";
import vSelect from "vue-select";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
  components: {
    vSelect,
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BForm,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedAccount: "",
      accounts: [],
      credentialsId: null,
      credentialsIdToEdit: null,
      required,
      api_key: null,
      appId: null,
      addOrEdit: null,
      appToAddCredentials: null,
      appToEditCredentials: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "title",
          label: "App Name",
        },

        "Credentials",
      ],
      items: [],
      appItems: [],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.showAllAccounts();
  },
  created() { },
  methods: {
    saveCredentials() {
      if (this.addOrEdit == "edit") {
        axios
          .post("/edit-credentials", {
            id: this.credentialsIdToEdit,
            appId: this.appId,
            api_key: this.api_key,
          })
          .then(({ data }) => {
            this.showToast("success", "SaveIcon", "Done", data.message);
          })
          .catch((error) => {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              error.data.message
            );
          });
      } else if (this.addOrEdit == "add") {
        axios
          .post("/store-credentials", {
            appId: this.appId,
            api_key: this.api_key,
          })
          .then(({ data }) => {
            if (data["crendentials"]) {
              axios
                .post("/app-credentials", {
                  id: this.appToAddCredentials,
                  credentials_id: data["crendentials"].id,
                })
                .then((response) => {
                  this.showToast(
                    "success",
                    "SaveIcon",
                    "Done",
                    response.data.message
                  );
                })
                .catch((error) => {
                  this.showToast(
                    "warning",
                    "AlertOctagonIcon",
                    "Alert",
                    error.data.message
                  );
                });
            } else {
              this.showToast(
                "warning",
                "AlertOctagonIcon",
                "Alert",
                data.message
              );
            }
          })
          .catch(console.error);
      }
    },
    setAppToAddCredentials(item) {
      var id = item.id;
      this.appId = "";
      this.api_key = "";
      this.addOrEdit = "add";
      this.appToAddCredentials = id;
      this.$root.$emit("bv::show::modal", "modal-default");
    },
    setAppToEditCredentials(item) {
      var id = item.id;
      this.appId = "";
      this.api_key = "";
      this.addOrEdit = "edit";
      this.appToEditCredentials = id;
      this.setDataToEdit(item.credentials_id);
      this.$root.$emit("bv::show::modal", "modal-default");
    },
    setDataToEdit(credentials_id) {
      this.credentialsIdToEdit = credentials_id;
      axios
        .post("/get-credentials", {
          id: credentials_id,
        })
        .then(({ data }) => {
          this.appId = data["credentials"][0].appId;
          this.api_key = data["credentials"][0].api_key;
        })
        .catch(console.error);
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async showAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then((response) => {
          response.data.accounts.forEach((element) => {
            this.accounts.push({
              title: element.account_name,
              value: element.id,
            });
          });
        })
        .catch(console.error);
    },
    showAccApps(account) {
      this.items = [];
      axios
        // .post(`/get-apps-by-account/${this.accSelected.value}`)
        .post(`/get-apps-by-account`, {
          accountId: account.value,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then(({ data }) => {
          if (data.apps) {
            this.items = data.apps;
            this.totalRows = this.items.length;
          }
        })
        .catch(console.error);
    },
  },
};
</script>
<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>